import React from 'react';
import { Button } from '@material-ui/core';
import SidebarSection from './sidebar-section';
import * as messages from '../../message.json';
import { ETSY_URL, SHARE_ON_FACEBOOK_URL } from '../../../utils/constants/urls';
import './page.scss';

const handleEtsyLinkClick = (): void => {
  window.location.href = ETSY_URL;
};

const Sidebar = (): JSX.Element => (
  <div className="sidebar">
    <SidebarSection title={messages.sidebar.upcomingShowsTitle}>
      <h3 style={{ paddingBottom: '.5rem', marginBottom: 0 }}>Holiday Artisan Faire</h3>
      <div style={{ fontSize: '.875rem', paddingLeft: '.5rem' }}>
        <p style={{ paddingBottom: '.5rem', margin: 0 }}>October 19, 10am - 4pm</p>
        <p style={{ paddingBottom: '.5rem', margin: 0 }}>
          <a href="https://www.roseville.ca.us/government/departments/parks/parks_places/maidu_community_center">
            Maidu Community Center
          </a>
        </p>
        <p style={{ paddingBottom: '.5rem', margin: 0 }}>
          <a href="https://maps.app.goo.gl/qT3dPiZAtDk8UEHPA">1550 Maidu Dr, Roseville, CA 95661</a>
        </p>
      </div>

    </SidebarSection>
    <SidebarSection title={messages.storeTitle}>
      <Button
        color="primary"
        variant="contained"
        onClick={handleEtsyLinkClick}
      >
        {messages.etsyLinkText}
      </Button>
    </SidebarSection>
    <SidebarSection title={messages.sidebar.contactTitle}>
      <p>
        {messages.revaName}
        <br />
        {messages.companyName}
        <br />
        {messages.phone}
      </p>
      <p><a href={`mailto:${messages.email}`}>{messages.email}</a></p>
    </SidebarSection>
    <SidebarSection title={messages.sidebar.shareTitle}>
      <iframe
        src={SHARE_ON_FACEBOOK_URL}
        width="77"
        height="28"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        title="Share on Facebook"
      />
    </SidebarSection>
  </div>
);

export default Sidebar;
