import React from 'react';
import './divder.scss';

interface DividerProps {
  height?: number;
  color?: 'light' | 'lighter' | 'dark' | 'darker';
  borderStyle?: 'dotted' | 'dashed' | 'solid';
  offsetX?: number;
  offsetY?: number;
}

const Divider = ({
  height, color, borderStyle, offsetX, offsetY,
}: DividerProps): JSX.Element => {
  const computedStyles = {
    width: offsetX ? `calc(100% + ${offsetX * 2}px)` : '100%',
    height: `${height}px`,
    borderWidth: `${height}px`,
    margin: `${offsetY}px -${offsetX}px`,
  };

  return (
    <hr className={`divider ${color} ${borderStyle}`} style={computedStyles} />
  );
};

Divider.defaultProps = {
  height: 1,
  color: 'light',
  borderStyle: 'solid',
  offsetX: 0,
  offsetY: 20,
};

export default Divider;
