import * as colors from '../../../../utils/constants/colors';

export const formControls = {
  MuiFormHelperText: {
    root: {
      fontFamily: 'inherit',
    },
  },
  MuiInput: {
    root: {
      color: colors.PEBBLE,
      fontFamily: '"Source Sans Pro", sans-serif',
      border: `1px solid ${colors.CLOUD}`,
      borderRadius: '3px',
      minHeight: '2.5em !important',
      '& input': {
        paddingLeft: '10px',
        paddingTop: 0,
        height: '2em',
      },
      '& input:disabled': {
        color: colors.STONE,
      },
      '&$focused': {
        border: 'none',
        '& input[aria-invalid="false"]': {
          color: '#424242',
          borderWidth: '1px 1px 2px 1px',
          borderLeftColor: '#d7d7d7',
          borderRightColor: '#d7d7d7',
          borderTopColor: '#d7d7d7',
          borderBottomColor: '#424242',
          borderStyle: 'solid',
          borderRadius: '3px',
        },
        '& input[aria-invalid="true"]': {
          borderWidth: '1px 1px 2px 1px',
          borderLeftColor: '#d7d7d7',
          borderRightColor: '#d7d7d7',
          borderTopColor: '#d7d7d7',
          borderBottomColor: '#e00000',
          borderStyle: 'solid',
          borderRadius: '3px',
        },
      },
    },
    underline: {
      '&:hover:before': {
        borderBottom: 'none !important',
      },
      '&::after': {
        borderBottom: 'none',
      },
      '&::before': {
        borderBottom: 'none',
      },
    },
    // inputType: {
    //   height: '2em',
    //   '&::-webkit-inner-spin-button': {
    //     '-webkit-appearance': 'none',
    //     margin: 0,
    //   },
    //   '&::-webkit-outer-spin-button': {
    //     '-webkit-appearance': 'none',
    //     margin: 0,
    //   },
    // },
    formControl: {
      marginTop: '1.2em !important',
      marginBottom: '1.2em',
    },
  },
  MuiInputLabel: {
    formControl: {
      top: '5px',
    },
    shrink: {
      transform: 'translate(0, 1.5px) scale(.95)',
    },
  },
  MuiCheckbox: {
    root: {
      '&$checked': {
        '& svg': {
          color: '#046b99',
        },
      },
    },
  },
  MuiRadio: {
    root: {
      color: colors.STONE,
      '&$checked': {
        color: `${colors.MED_BROWN} !important`,
      },
    },
  },
  MuiFormControl: {
    fullWidth: {
      width: '100%',
    },
  },
  MuiSelect: {
    select: {
      paddingTop: '11px',
    },
  },
  MuiFormLabel: {
    root: {
      paddingLeft: '10px',
      color: colors.PEBBLE,
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '.875em',
      '&$focused': {
        color: `${colors.PEBBLE} !important`,
        paddingLeft: '10px',
        paddingBottom: '5px',
        top: '-13px !important',
      },
      '&$error': {
        color: `${colors.RED} !important`,
        paddingLeft: '10px',
      },
    },
    filled: {
      top: '-13px !important',
    },
  },
};
