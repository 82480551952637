import React from 'react';
import Carousel from 'react-material-ui-carousel';
import * as messages from '../../message.json';
import Page from '../../components/page';
import Main from '../../components/page/main';
import Sidebar from '../../components/page/sidebar';

const images = [
  '1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg',
  '9.jpg', '10.jpg', '11.jpg', '12.jpg', '13.jpg', '14.jpg', '15.jpg', '16.jpg',
  '17.jpg', '18.jpg',
];

const Gallery = (): JSX.Element => (
  <Page>
    <Main title={messages.galleryTitle}>
      <Carousel
        animation="slide"
        stopAutoPlayOnHover
        swipe
        indicators={false}
        className="gallery"
      >
        {images.map((file: string) => (
          <div key={file}>
            <a className="gallery-image" href={`${process.env.PUBLIC_URL}/${file}`}>
              <img src={`${process.env.PUBLIC_URL}/${file}`} alt="Reva's jewelry" />
            </a>
          </div>
        ))}
      </Carousel>
    </Main>
    <Sidebar />
  </Page>
);

export default Gallery;
