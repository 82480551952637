import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import Page from '../../components/page';
import Main from '../../components/page/main';
import Sidebar from '../../components/page/sidebar';
import './contact.scss';
import Divider from '../../components/divider';
import * as messages from '../../message.json';
import { MAILCHIMP_SUBSCRIBE_FORM_ACTION } from '../../../utils/constants/urls';

const Contact = (): JSX.Element => {
  const [formData, setFormData] = useState({
    EMAIL: '',
    FNAME: '',
    LNAME: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Page>
      <Main title={messages.contactTitle}>
        <div className="section-1">
          <p>{messages.contact1}</p>
          <div className="contact-info">
            <p>
              {messages.revaName}
              <br />
              {messages.companyName}
              <br />
              {messages.address}
              <br />
              <strong>Phone: </strong>
              {messages.phone}
              <br />
              <strong>Email: </strong>
              <a href={`mailto:${messages.email}`}>{messages.email}</a>
            </p>
          </div>
        </div>
        <Divider />
        <div className="section-2">
          <h2>Newsletter</h2>
          <p>{messages.contact2}</p>
          <div id="mc_embed_signup">
            <form
              action={MAILCHIMP_SUBSCRIBE_FORM_ACTION}
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
            >
              <div id="mc_embed_signup_scroll">
                <h3>Subscribe</h3>
                <div className="indicates-required">
                  <span className="asterisk">*</span>
                  {' '}
                  indicates required
                </div>
                <div className="mc-field-group">
                  <TextField
                    id="mce-EMAIL"
                    name="EMAIL"
                    type="email"
                    value={formData.EMAIL}
                    onChange={handleInputChange}
                    label="Email Address"
                    required
                    fullWidth
                  />
                </div>
                <div className="mc-field-group">
                  <TextField
                    id="mce-FNAME"
                    name="FNAME"
                    type="text"
                    value={formData.FNAME}
                    onChange={handleInputChange}
                    label="First Name"
                    fullWidth
                  />
                </div>
                <div className="mc-field-group">
                  <TextField
                    id="mce-LNAME"
                    name="LNAME"
                    type="text"
                    value={formData.LNAME}
                    onChange={handleInputChange}
                    label="Last Name"
                    fullWidth
                  />
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }} />
                  <div className="response" id="mce-success-response" style={{ display: 'none' }} />
                </div>
                <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
                  <input type="text" name="b_b1c86d2ba2f2ea8c8e5880bba_6a106dc8c6" value="" />
                </div>
                <div className="clear">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                  >
                    Subscribe
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Main>
      <Sidebar />
    </Page>
  );
};

export default Contact;
