import * as colors from '../../../../utils/constants/colors';

export const tableOverrides = {
  SortingControl: {
    tooltipRoot: {
      display: 'none',
    },
  },
  MuiTableCell: {
    head: {
      paddingTop: 0,
      paddingBottom: 0,
      fontWeight: 700,
      color: colors.PEBBLE,
    },
    body: {
      borderBottom: `1px solid ${colors.CLOUD}`,
      color: colors.PEBBLE,
    },
  },
  MuiTableSortLabel: {
    active: {
      opacity: 1,
      '& svg': {
        width: '.875em',
        height: '.875em',
        top: '2px !important',
        color: '#fff !important',
      },
    },
  },
  TableHeaderCell: {
    cell: {
      '&:first-child': {
        paddingLeft: '16px',
      },
    },
  },
  TableCell: {
    cell: {
      '&:first-child': {
        paddingLeft: '16px',
      },
    },
  },
};
