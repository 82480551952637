import React from 'react';
import Divider from '../divider';

interface MainProps {
  children: React.ReactNode;
  title: string;
}

const Main = ({ children, title }: MainProps): JSX.Element => (
  <div className="main">
    <h1 className="title">{title}</h1>
    <Divider />
    {children}
  </div>
);

export default Main;
