import React from 'react';
import Page from '../../components/page';
import Main from '../../components/page/main';
import Sidebar from '../../components/page/sidebar';
import './other.scss';
import Divider from '../../components/divider';
import * as messages from '../../message.json';
import { SHARE_ON_FACEBOOK_URL } from '../../../utils/constants/urls';

const Other = (): JSX.Element => (
  <Page>
    <Main title={messages.otherFunStuffTitle}>
      <div className="facebook">
        <h2>Share on Facebook</h2>
        <iframe
          src={SHARE_ON_FACEBOOK_URL}
          width="77"
          height="28"
          style={{ border: 'none', overflow: 'hidden' }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          title="Share on Facebook"
        />
      </div>
      <Divider />
      <div className="pinterest">
        <h2>Follow Me On Pinterest</h2>
        <a href="https://www.pinterest.com/reva29/" className="pinterest-button">
          <img src={`${process.env.PUBLIC_URL}/pinterest-button.JPG`} alt="Pinterest Button" />
        </a>
      </div>
    </Main>
    <Sidebar />
  </Page>
);

export default Other;
