import React from 'react';
import * as messages from '../../message.json';
import Page from '../../components/page';
import Main from '../../components/page/main';
import Sidebar from '../../components/page/sidebar';
import './store.scss';
import { ETSY_URL } from '../../../utils/constants/urls';

const Store = (): JSX.Element => (
  <Page>
    <Main title={messages.storeTitle}>
      <div className="store-image">
        <img src={`${process.env.PUBLIC_URL}/store-img.png`} alt="bracelet over rocks" />
      </div>
      <p className="etsy-link"><a href={ETSY_URL}>{messages.etsyLinkText}</a></p>
    </Main>
    <Sidebar />
  </Page>
);

export default Store;
