import * as colors from '../../../../utils/constants/colors';
import { buttonOverrides } from './button';
import { formControls } from './form-controls';
import { tableOverrides } from './table';

const htmlAndBodyStyles = {
  fontFamily: '\'Roboto\', sans-serif',
  fontSize: 18,
  color: '#424242',
  backgroundColor: '#f5f5f5',
  lineHeight: 1.3,
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
};

export const overrides = {
  MuiCssBaseline: {
    '@global': {
      html: htmlAndBodyStyles,
      body: htmlAndBodyStyles,
    },
  },
  MuiCircularProgress: {
    colorPrimary: {
      color: colors.SAGE_GREEN,
    },
  },
  MuiLinearProgress: {
    colorPrimary: {
      backgroundColor: colors.SAGE_GREEN,
    },
    barColorPrimary: {
      backgroundColor: colors.LIGHT_BROWN,
    },
  },
  MuiMenuItem: {
    root: {
      color: colors.PEBBLE,
      fontFamily: '"Source Sans Pro", sans-serif',
    },
  },
  MuiTypography: {
    root: {
      fontFamily: '"Source Sans Pro", sans-serif !important',
    },
    title: {
      color: colors.PEBBLE,
      fontWeight: 600,
    },
    subheading: {
      color: `${colors.STONE} !important`,
    },
  },
  ...buttonOverrides,
  ...formControls,
  ...tableOverrides,
};
