import React from 'react';

interface SidebarSectionProps {
  title: string;
  children: React.ReactNode;
}

const SidebarSection = ({ title, children }: SidebarSectionProps): JSX.Element => (
  <div className="sidebar-section">
    <h2>{title}</h2>
    <div className="sidebar-section-content">{children}</div>
  </div>
);

export default SidebarSection;
