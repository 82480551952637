import { Route } from '../routes';
import LandingPage from './pages/landing';
import Store from './pages/store';
import About from './pages/about';
import Gallery from './pages/gallery';
import Other from './pages/other';
import Contact from './pages/contact';

const CommonRoutes: Route[] = [
  {
    path: '/',
    name: 'Landing Page',
    component: LandingPage,
    exact: true,
  },
  {
    path: '/about',
    name: 'About Page',
    component: About,
    exact: true,
  },
  {
    path: '/store',
    name: 'Store',
    component: Store,
    exact: true,
  },
  {
    path: '/gallery',
    name: 'Gallery Page',
    component: Gallery,
    exact: true,
  },
  {
    path: '/other',
    name: 'Other Fun Stuff',
    component: Other,
    exact: true,
  },
  {
    path: '/contact',
    name: 'Contact Us Page',
    component: Contact,
    exact: true,
  },
];

export default CommonRoutes;
