import React from 'react';
import './page.scss';
import { Paper } from '@material-ui/core';

interface PageProps {
    children: React.ReactNode,
}

const PageLayout = ({ children }: PageProps): JSX.Element => (
  <div className="page-wr">
    <Paper className="page-container">{children}</Paper>
  </div>
);

export default PageLayout;
