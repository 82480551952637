import React from 'react';
import CommonRoutes from './common/routes';

export interface Route {
  path: string;
  name: string;
  exact: boolean;
  component: React.ElementType;
  props?: unknown;
}

export const routes = [
  ...CommonRoutes,
];
