import React from 'react';
import Page from '../../components/page';
import Sidebar from '../../components/page/sidebar';
import Main from '../../components/page/main';
import * as messages from '../../message.json';
import Divider from '../../components/divider';
import './about.scss';

const About = (): JSX.Element => (
  <Page>
    <Main title={messages.aboutTitle}>
      <div className="section-1">
        <img src={`${process.env.PUBLIC_URL}/reva.jpg`} alt="Reva" />
        <p>{messages.about1}</p>
      </div>
      <Divider />
      <div className="section-2">
        <img src={`${process.env.PUBLIC_URL}/home-image-2.jpg`} alt="Necklace on moss" />
        <p>{messages.about2}</p>
      </div>
      <Divider />
      <div className="section-3">
        <img src={`${process.env.PUBLIC_URL}/home-image-3.jpg`} alt="Earing on sand" />
      </div>
    </Main>
    <Sidebar />
  </Page>
);

export default About;
