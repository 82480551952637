import * as colors from '../../../../utils/constants/colors';

export const buttonOverrides = {
  MuiButton: {
    contained: {
      '&:hover': {
        backgroundColor: colors.SAGE_GREEN,
      },
    },
    containedPrimary: {
      color: colors.WHITE,
      backgroundColor: colors.DARK_BROWN,
      '&:hover': {
        backgroundColor: `${colors.SAGE_GREEN} !important`,
        color: colors.DARK_BROWN,
      },
    },
    containedSecondary: {
      color: colors.DARK_BROWN,
      backgroundColor: colors.WHITE,
      '&:hover': {
        backgroundColor: `${colors.SAGE_GREEN} !important`,
      },
    },
    root: {
      fontWeight: 400,
      fontFamily: '"Source Sans Pro", sans-serif',
      fontSize: '1em',
      lineHeight: 1.3,
      letterSpacing: '.01em',
      '&:hover': {
        backgroundColor: `${colors.LIGHT_BROWN} !important`,
        color: colors.WHITE,
      },
    },
  },
  MuiButtonBase: {
    root: {
      '&$disabled': {
        backgroundColor: '#d7d7d7 !important',
        color: '#8e8e8e !important',
      },
    },
  },
  MuiIconButton: {
    root: {
      width: '35px',
      height: '35px',
      marginLeft: '10px',
    },
  },
};
