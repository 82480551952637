export const DARK_BROWN = '#342721';
export const MED_BROWN = '#4F3C32';
export const LIGHT_BROWN = '#846454';
export const SAGE_GREEN = '#829b71';
export const PEBBLE = '#424242';
export const STONE = '#666766';
export const RED = '#e00000';
export const CLOUD = '#d7d7d7';
export const ALABASTER = '#fbfbfb';
export const BLACK = '#000000';
export const WHITE = '#ffffff';
