import moment from 'moment';
import React from 'react';
import './footer.scss';

// TODO: Update 'Contact Us' email address
// TODO: Update 'User Guide' to point to real resource
// TODO: Set up ability to maintain version number as a configuration and use it here
const Footer = (): JSX.Element => (
  <footer className="footer">
    <div className="container">
      <div className="copyright">
        <p>{`©${moment().format('YYYY')} Ziva Shayna Design Company`}</p>
        <p>All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;
