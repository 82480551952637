import React, { useState } from 'react';
import './header.scss';
import MenuIcon from '@material-ui/icons/Menu';
import {
  AppBar, Button, Drawer, ListItemText, MenuItem, MenuList, Toolbar,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { DARK_BROWN } from '../../../../utils/constants/colors';

function Header(): JSX.Element {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const menuItems = [
    { label: 'Home', href: '/' },
    { label: 'About', href: '/about' },
    { label: 'Store', href: '/store' },
    { label: 'Gallery', href: '/gallery' },
    { label: 'Other Fun Stuff', href: '/other' },
    { label: 'Contact  Me', href: '/contact' },

  ];

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent): void => {
    if (event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const activeRoute = (routeName: string): boolean => window.location.pathname === routeName;

  return (
    <header className="header">
      <AppBar className="app-bar" position="absolute">
        <Toolbar className="toolbar">
          <div className="mobile-header">
            <div className="mobile-logo">
              <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="ZivaShayna Logo" />
            </div>
            <div className="mobile-menu">
              <Button onClick={toggleDrawer(true)}>
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <MenuList className="mobile-menu-list">
                  {menuItems.map((item) => (
                    <NavLink key={item.label} to={item.href}>
                      <MenuItem selected={activeRoute(item.href)} onClick={toggleDrawer(false)}>
                        <ListItemText primary={item.label} />
                      </MenuItem>
                    </NavLink>
                  ))}
                </MenuList>
              </Drawer>
            </div>
          </div>
          <div className="desktop-menu">
            {menuItems.map(({ label, href }) => (
              <Button
                {...{
                  key: label,
                  color: 'inherit',
                  to: href,
                  component: NavLink,
                  activeStyle: { ...{ backgroundColor: DARK_BROWN } },
                  exact: true,
                }}
              >
                {label}
              </Button>
            ))}
          </div>
        </Toolbar>
      </AppBar>
      <div className="logo-banner">
        <img src={`${process.env.PUBLIC_URL}/header-logo.jpg`} alt="Ziva Shayna Logo" />
      </div>
    </header>
  );
}

export default Header;
