import React from 'react';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import {
  BrowserRouter, Route, Switch, RouteComponentProps,
} from 'react-router-dom';
import Header from './header';
import { routes } from '../../../routes';
import Footer from './footer';
import './root-layout.scss';
import { overrides } from './material-ui-overrides';

export type RouteParams = { [key: string]: string | undefined };

const theme = createMuiTheme({
  overrides,
  props: {},
});

const RootLayout = (): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Header />
      <div className="content">
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              exact={route.exact}
              render={(props: RouteComponentProps<RouteParams>): React.ReactNode => (
                <route.component
                  name={route.name}
                  {...props}
                  {...route.props}
                />
              )}
            />
          ))}
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  </ThemeProvider>
);

export default RootLayout;
