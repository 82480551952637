import React from 'react';
import ReactDOM from 'react-dom';
import RootLayout from 'common/components/root-layout';

ReactDOM.render(
  <React.StrictMode>
    <RootLayout />
  </React.StrictMode>,
  document.getElementById('root'),
);
